/* ------------------------------------------------
  Project:   Misto - Factory and Industrial HTML5 Template
  Author:    ThemeHt
------------------------------------------------ */
/* ------------------------
    Table of Contents

  1. General
  2. Typography
  3. Helper class
  4. Text color
  5. Background color
  6. Jarallax
  7. Pattern
  8. Border Radius
  9. Banner
  10. Countdown
  11. Terms and Conditions
  12. Privacy Policy
  13. Contact
  14. About Us
  15. Video
  16. Side Background


/* ------------------------
    General
------------------------*/
@import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import "src/assets/css/animate-min.css";

body {font-family: 'Rubik', sans-serif; font-weight: normal; font-style: normal; font-size: 15px; line-height: 1.5; color:#282929;}


/* ------------------------
    Typography
------------------------*/
h1, h2, h3, h4, h5, h6{font-weight:normal; margin-top: 0px; margin-bottom: 0px; text-transform: capitalize;}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { color: inherit; }
h1 { font-size: 70px; font-style: normal; line-height: 80px; font-weight: 700; text-transform: capitalize; color: #34b4fb;}
h2 { font-size: 40px; font-style: normal; line-height: 46px; font-weight: 500; color: #23244a; text-transform: capitalize; margin-bottom: 10px; position: relative;}
h3 { font-size: 30px; font-style: normal; line-height: 36px; }
h4 { font-size: 24px; font-style: normal; text-transform: uppercase; margin-bottom: 10px; font-weight: 500; line-height: 30px; color: #23244a;}
h5 { font-size: 20px; font-style: normal; text-transform: uppercase; margin-bottom: 10px; font-weight: 500; line-height: 26px; color: #23244a;}
h6 { font-size: 18px; font-style: normal; line-height: 24px; }
ul { margin: 0px; padding: 0px; }


/* ------------------------
    Helper class
------------------------*/

.lead{font-weight: normal; font-size: 16px; line-height: 30px;}
.z-index-0{z-index: 0; position: relative;}
.z-index-1{z-index: 1; position: relative;}
section{padding: 80px 0;}
.section-title{margin-bottom:50px;}
.section-title p{font-size: 16px;}
.theme-bg .section-title p, .dark-bg .section-title p{color: rgba(255,255,255,0.8)}

.line-h-2{line-height: 1.6;}
.line-h-3{line-height: 1.7;}
.line-h-4{line-height: 1.8;}
.line-h-5{line-height: 1.9;}

.letter-space-1{letter-spacing: 5px;}
.letter-space-2{letter-spacing: 10px;}
.letter-space-3{letter-spacing: 15px;}


/* ------------------------
    Text color
------------------------*/
.text-theme{color:#34b4fb;}
.text-black{color:#23244a !important;}
.text-grey{color:#aaaaaa;}
.text-yellow{color:#34b4fb;}
.text-rgba{color: rgba(255,255,255,0.8);}


/* ------------------------
    Background color
------------------------*/
.white-bg{background-color:#ffffff !important;}
.dark-bg{background-color:#101112;}
.grey-bg{background-color:#f8f8f8;}
.theme-bg{background-color:#34b4fb;}
.transparent-bg{background-color: inherit !important;}


/* ------------------------
    Pattern
------------------------*/
.pattern{width: 100%; background-size: inherit !important; background-position: 0px 0px; background-repeat: repeat !important; animation: animatedBackground 20s linear infinite;
-ms-animation: animatedBackground 20s linear infinite; -moz-animation: animatedBackground 20s linear infinite; -webkit-animation: animatedBackground 20s linear infinite;}


/* ------------------------
    Banner
------------------------*/
.fullscreen-banner{position: relative; min-height: 800px;}
[data-bg-img]{background-size: cover; background-position: center center; background-repeat: no-repeat;}
.align-center{position: absolute; top: 50%; width: 100%; z-index: 9;}
.align-end{position: absolute; bottom: 0; width: 100%; z-index: 9;}
.fullscreen-banner .btn{color: #ffffff;}
.banner-1::before {border: 10px solid rgba(255,255,255,0.1); content: ""; height: 120%; left: 50%; position: absolute;
  top: -10%; width: 80%; z-index: -1;}
.fullscreen-banner .owl-carousel, .fullscreen-banner .owl-stage-outer, .fullscreen-banner .owl-stage, .fullscreen-banner .owl-item, .fullscreen-banner .item { height: 100%; }


/* ------------------------
    Countdown
------------------------*/
.countdown{text-align: center; width: 100%}
.countdown > li{margin-bottom: 50px; font-weight: bold; display: block; text-align: center; list-style-type: none;}
.countdown > li:last-child{margin-bottom: 0;}
.countdown > li span{font-size:70px; font-weight:500; line-height:60px; margin-bottom: 20px; display: inline-block;}
.countdown > li p{font-size:20px; text-transform: uppercase; line-height:30px; margin-bottom: 0; color: #34b4fb;}
.dark-bg .countdown > li{border-color:rgba(255,255,255,0.2) }

/* ------------------------
    Terms and Conditions
------------------------*/
.terms-and-conditions .title{margin-bottom:10px;}
.terms-and-conditions p{margin-bottom:25px;}
.terms-and-conditions ul.list{margin-bottom:20px; display:block;}
.terms-and-conditions ul.list li i{padding-right:12px; font-size:15px;}

/* ------------------------
    Privacy Policy
------------------------*/
.privacy-policy .title{margin-bottom:10px;}
.privacy-policy p{margin-bottom:25px;}
.privacy-policy ul.list{margin-bottom:20px; display:block;}
.privacy-policy ul.list li i{padding-right:12px; font-size:15px;}

/* ------------------------
    Contact
------------------------*/
.contact-main {/*background: #fff; padding: 30px;*/ border-bottom: 2px solid #34b4fb;}
.contact-info li {padding-left: 50px; position: relative;}
.contact-info li i{position: absolute; left: 0; top: 0; line-height: 24px; font-size: 30px; color: #34b4fb; text-align: center;}
.contact-info li span{font-size: 16px; color: #23244a; font-weight: bold; text-transform: uppercase; display: block; margin-bottom: 8px;}
.contact-info li p{margin-bottom: 0; line-height: 24px;}
.contact-info li a{color: #555;}
.dark-bg .contact-info li a{color: #ffffff;}
.contact-info li a:hover{color: #34b4fb;}
.theme-bg .contact-info span, .dark-bg .contact-info span{color: #ffffff}
.theme-bg .contact-info li i{color: #ffffff;}
.theme-bg .contact-info li a{color: #ffffff;}
.theme-bg .contact-info li a:hover{color: #23244a;}
.contact-box {position: absolute; top: 50%; z-index: 99; transform: translateY(-50%); width: 100%;}

.form-group{margin-bottom: 20px; position: relative;}
 label{font-size: 14px; color: #333}
.form-control{height: 50px; border-radius: 0; font-size: 13px; background: none; border: none; border-bottom: 2px solid #f5f5f5; box-shadow: none;}
.grey-bg .form-control{background: #ffffff}
textarea.form-control{height: 150px;}
.form-control:focus{box-shadow: none; border-color: #34b4fb;}
select.form-control {/*-moz-appearance: none; -webkit-appearance: none; appearance: none;*/ height: 50px !important;}
iframe{width: 100%; border: none; pointer-events: auto;}
.iframe-h{height: 500px !important;}
.iframe-h-2{height: 900px !important;}
.help-block.with-errors {position: absolute; color: red; right: 0; font-size: 10px; line-height: 14px;}
.form-control::-moz-placeholder{color: #555}
.map iframe {border: 0px; width: 100%; height: 100%; z-index: 1; -webkit-filter: grayscale(100%); -moz-filter: grayscale(100%); -ms-filter: grayscale(100%); -o-filter: grayscale(100%); filter: grayscale(100%);}


.contact-btn {cursor: pointer; display: inline-block; position: fixed; right: -39px; top: 40%; z-index: 99;
transform: translateY(-50%) rotate(90deg); -webkit-transform: translateY(-50%) rotate(90deg); -o-transform: translateY(-50%) rotate(90deg); -ms-transform: translateY(-50%) rotate(90deg); -moz-transform: translateY(-50%) rotate(90deg);}
.contact-bg { background: #ffffff; color: #34b4fb; text-transform: uppercase; position: relative; display: block; padding: 10px; font-weight: 500; z-index: 10; -webkit-box-shadow: 0 0 13px 3px rgba(0,0,0,0.1); -moz-box-shadow: 0 0 13px 3px rgba(0,0,0,0.1); box-shadow: 0 0 13px 3px rgba(0,0,0,0.1);}
.contact-btn:hover .contact-bg{background: #34b4fb; color: #ffffff}
.contact-form {background: #ffffff; height: 100%; border: 1px solid rgba(0, 0, 0, 0.2); box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); padding: 80px 30px 30px; width: 450px; position: fixed; right:-600px; top: 0; z-index: 2000;}
.close-btn {background: #34b4fb; color: #fff !important; cursor: pointer; font-weight: bold; padding: 7px 15px; position: absolute; right: 20px; top: 10px;}
.header-3 .contact-btn{position: inherit; transform: inherit; top: inherit; right: inherit;}
.header-3 .contact-bg {background: #34b4fb; box-shadow: none; color: #fff; padding: 25px 40px;}

/* ------------------------
    About Us
------------------------*/
.about-img{position: relative;}
.about-img::before {border:5px solid rgba(255, 255, 255, 0.2); bottom: 14px; content: ""; left: 14px; position: absolute; right: 14px; top: 14px;}


/* ------------------------
    Video
------------------------*/
.video-btn {display: inline-block;}
.video-btn-pos {left: 50%; position: absolute; top: 50%; margin-top: -22.5px; margin-left: -50px;}
.video-btn .btn-play {background: #34b4fb; color: #fff; height: 45px; width: 100px; line-height: 45px; -webkit-border-radius: 50px;
 border-radius: 5px; text-align: center; font-size: 26px; cursor: pointer; display: inline-block;}
.video-btn .btn-play i{line-height: inherit;}
.theme-bg .video-btn .btn-play, .dark-bg .video-btn .btn-play{background: #ffffff; border-color: #ffffff; color: #34b4fb;}
.html5-video {height: 100%; left: 0; opacity: 1; overflow: hidden; position: absolute; top: 0; width: 100%; z-index: -2;}
.html5-video video {width: 100%;}


/* ------------------------
    Side Background
------------------------*/
.img-side img{max-width: 100%}
.img-side.img-right {right: 0;}
.img-side.img-left {left: 0;}
.img-side {padding: 0; position: absolute!important; top: 0px; height: 100%; overflow: hidden; z-index: 9;}
.img-side .owl-carousel, .img-side .owl-stage-outer, .img-side .owl-stage, .img-side .owl-stage, .img-side .owl-item, .img-side .owl-item .item{height: 100%}


