/* ------------------------------------------------
  Project:   Misto - Factory and Industrial HTML5 Template
  Author:    ThemeHt
------------------------------------------------ */
/* ------------------------
    Table of Contents

  1. General
  2. Transition
  3. Transform translate
  4. Box Shadow
  5. Pre Loader
  6. Background Overlay
  7. Scroll to Top
  8. Header
  9. Footer
  10. Page Title
  11. Flat Icon


/* ------------------------
    General
------------------------*/
a, a:focus { text-decoration: none !important; }
a:focus, a:hover { color: #2E4F97; text-decoration: none !important; }
a, button, input { outline: medium none !important; color: #2E4F97; }

*::-moz-selection { background: #2E4F97; color: #fff; text-shadow: none; }
::-moz-selection { background: #2E4F97; color: #fff; text-shadow: none; }
::selection { background: #2E4F97; color: #fff; text-shadow: none; }


/* ------------------------
    Transition
------------------------*/
a, i, .btn, button, img, span, .navbar-nav .nav-link:before, .dropdown-item, .nav-link.dropdown-toggle::after, .owl-item, .contact-bg, .service-item, .service-description,
.featured-item .featured-icon i, .featured-item, .featured-icon, .featured-item.bottom-icon span, .price-table,
.price-table:hover .price-value, .portfolio-filter button, .portfolio-item:before, .portfolio-item a.popup, .portfolio-hover, .portfolio-icon, .portfolio-title, .post, .post-date, .post .post-desc,
.sidebar-links li a, .footer-list li a:before, .team-member:hover .team-social-icon, .team-description
{-webkit-transition: all 0.5s ease-in-out 0s; -moz-transition: all 0.5s ease-in-out 0s; -ms-transition: all 0.5s ease-in-out 0s; -o-transition: all 0.5s ease-in-out 0s; transition: all 0.5s ease-in-out 0s; }


/* ------------------------
    Transform translate
------------------------*/

/*--translate-X--*/
.page-breadcrumb, .banner-1::before {transform: translateX(-50%); -webkit-transform: translateX(-50%); -o-transform: translateX(-50%); -ms-transform: translateX(-50%); -moz-transform: translateX(-50%);}

/*--translate-Y--*/
.transform-y, .footer-contact, .service-flip-desc .service-flip-text, .align-center, .clear-loader, .search-box button, .widget-searchbox .search-btn, .post-meta ul li::before, .ht-progress-bar .progress-parcent, .team-member .team-social-icon, .service-icon, .portfolio-desc, .counter.style-3 i, .accordion .card-header a span{transform: translateY(-50%); -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%);}

/*--translate-XY--*/
.clear-loader, .testimonial.style-2 .testimonial-avatar,
.accordion .card-header a span:before{transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); -ms-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%);}

/*--translate-X-5--*/
.navbar-nav .dropdown-menu .nav-link:focus, .navbar-nav .dropdown-menu .nav-link:hover{transform: translateX(5px); -webkit-transform: translateX(5px); -o-transform: translateX(5px); -ms-transform: translateX(5px); -moz-transform: translateX(5px);}


/* ------------------------
    Box Shadow
------------------------*/
.navbar .dropdown-menu, .price-header, .price-header .price-month, .featured-item:hover, .featured-item.active, .testimonial.style-2 .testimonial-content, .testimonial.style-3,
.counter, .blog-details, .contact-main, .service-flip-back, .service-flip-front, .team-desc, .accordion-item, .featured-item.style-3:hover, .price-table:hover, .portfolio-item:hover, .box-shadow, .team-member, .tab.style-2 .nav-tabs, .post
{-webkit-box-shadow: 5px 8px 30px 0 rgba(31, 35, 37, 0.08); -moz-box-shadow: 5px 8px 30px 0 rgba(31, 35, 37, 0.08); box-shadow: 5px 8px 30px 0 rgba(31, 35, 37, 0.08);}


/* ------------------------
    Pre Loader
------------------------*/
#ht-preloader {background: #ffffff; bottom: 0; height: 100%; left: 0; overflow: hidden !important; position: fixed; right: 0; text-align: center; top: 0; width: 100%; z-index: 99999;}
.clear-loader {z-index: 999; box-sizing: border-box; display: inline-block; left: 50%; position: absolute; text-align: center; top: 50%;}


/* ------------------------
    Background Overlay
------------------------*/
[data-overlay] {position:relative; z-index:0;}
[data-overlay]:before, .slide-overlay:before {position: absolute;  content: '';  background-color: #000000;  width: 100%;  height: 100%;  top: 0;  left: 0; z-index:-1;}
[data-overlay].white-overlay:before{background-color:#fff;}
[data-overlay].theme-overlay:before{background-color:#2E4F97;}
[data-overlay="1"]:before{opacity:0.1;}
[data-overlay="2"]:before{opacity:0.2;}
[data-overlay="3"]:before{opacity:0.3;}
[data-overlay="4"]:before{opacity:0.4;}
[data-overlay="5"]:before{opacity:0.5;}
[data-overlay="6"]:before{opacity:0.6;}
[data-overlay="7"]:before{opacity:0.7;}
[data-overlay="8"]:before{opacity:0.8;}
[data-overlay="9"]:before{opacity:0.9;}
[data-overlay="10"]:before{opacity:1;}
[data-overlay="0"]:before{opacity:0;}
.slide-overlay:before{background-color:rgba(0,0,0,0.6);}


/* ------------------------
    Scroll to Top
------------------------*/
.scroll-top {position: fixed; bottom: 30px; right: 30px; z-index: 600; opacity: 0; visibility: hidden; -webkit-transition: all 0.8s ease-in-out; transition: all 0.8s ease-in-out; -webkit-transform: translate3d(0, 200%, 0); -ms-transform: translate3d(0, 200%, 0); transform: translate3d(0, 200%, 0);}
.scroll-top a, .scroll-top a:visited {display: block; height: 40px; width: 40px; line-height: 40px; text-align: center; -webkit-box-shadow: 0 0 15px 6px rgba(0,0,0,0.1); -moz-box-shadow: 0 0 15px 6px rgba(0,0,0,0.1); box-shadow: 0 0 15px 6px rgba(0,0,0,0.1); -webkit-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; font-size:22px; color: #ffffff; background: #2E4F97;}
.scroll-top a:hover, .scroll-top a:focus {color: #2E4F97; background: #ffffff;}
.scroll-top.scroll-visible {opacity: 1; visibility: visible; -webkit-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0);}


/* ------------------------
    Header
------------------------*/
.header {position: absolute; top: 0; width: 100%; z-index: 1200;}
.top-bar {padding: 15px 0 10px;}
.topbar-link li a{color: #ffffff}
.topbar-link li a i{margin-right: 5px;}
.topbar-link li a:hover{color: #2E4F97}

.search-box {display: inline-block; position: relative;}
.search-box input[type="text"], .search-box input[type="search"] {
  background: rgba(255, 255, 255, 0.1); border-radius: 50px; color: #ffffff; display: block; height: 40px; line-height: 25px; padding: 10px 50px 10px 20px;
  position: relative; width: 250px; border-color: transparent;}
.search-box button {background: none; color: #ffffff; display: block; font-weight: normal; position: absolute;
  right: 10px; top: 50%; padding: 0; border-radius: 50px;}
.search-box button:before, .search-box button:after{display: none;}
.search-box .form-control::-moz-placeholder{color: #ffffff;}

#header-wrap{width: 100%; background: rgba(255,255,255,0.39);}
#header-wrap.fixed-header {position: fixed; top:0; left:0; width: 100%; z-index: 999 !important; background: #121628 !important;}
.logo img {height: 50px;}
#header-wrap.fixed-header .logo img {height: 45px;}

.top-bar .top-social-list li a{color: #ffffff;}

/*--navigation--*/
.navbar{padding: 0;}
.navbar-nav{position: relative;}
.navbar-nav .nav-item.dropdown .dropdown-menu {background: #ffffff; padding: 0; left: 0; border:none; -webkit-transition: all 400ms ease-out; -o-transition: all 400ms ease-out; transition: all 400ms ease-out; top: 120%; margin-top: 0; }
.nav-item.dropdown .dropdown-menu a {display: block; padding: 10px 20px; border-radius: 5px; white-space: nowrap; position: relative; background: none; color: #23244a; font-size: 14px; font-weight: 400; }
.nav-item.dropdown .dropdown-menu a:hover { background: #2E4F97; color: #ffffff; padding-left: 15px; }
.navbar-brand{margin-right: 0; padding: 0;}


@media (min-width:992px) {
  .navbar-nav .dropdown-menu { min-width: 10em; max-width: 15em; }
  .dropdown-menu.w-100,
  .navbar-nav .dropdown-menu .dropdown-submenu .dropdown-menu{min-width: inherit; max-width: inherit;}
  .navbar-nav .dropdown > .dropdown-menu { opacity: 0; position: absolute; display: block; pointer-events: none; background: #ffffff; }
  .navbar-nav .dropdown:hover > .dropdown-menu { display: block; opacity: 1; pointer-events: auto; visibility: visible; top: 100%; -webkit-transition: all 0.5s ease-in-out 0s; -moz-transition: all 0.5s ease-in-out 0s; -ms-transition: all 0.5s ease-in-out 0s; -o-transition: all 0.5s ease-in-out 0s; transition: all 0.5s ease-in-out 0s; }
  .navbar-nav .dropdown-submenu:hover .dropdown-menu { top: 0; }
  .nav-link.dropdown-toggle::after {font-size: 0.7rem; margin-left: .355em; border: none; content: '\f078'; vertical-align: inherit; width: 10px; font-family: 'Font Awesome 5 Free'; font-weight: 900;}
  .nav-link.dropdown-toggle:hover::after {transform: rotate(-180deg);}
  .navbar-nav .nav-link { line-height: 80px; }
  .navbar-nav .dropdown-submenu .dropdown-toggle::after {border-top: 0.125rem solid #23244a; border-right: 0.125rem solid #23244a; border-bottom: none; border-left: none; width: 0.425rem; height: 0.425rem; position: absolute; right: 20px; margin: 0; vertical-align: middle; top: 50%; transform: translateY(-50%) rotate(45deg); }
  .navbar-nav .dropdown-submenu .dropdown-toggle:hover::after {border-top: 0.125rem solid #ffffff; border-right: 0.125rem solid #ffffff;}
}

.navbar-nav .nav-item{margin: 0 15px;}
.navbar-nav .nav-link{color: #ffffff; font-weight: 500; padding: 0 !important; text-transform: capitalize; flex-direction: column; align-items: flex-start; overflow: hidden; position: relative;}
.navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus, .navbar-nav .nav-item .nav-link.active{color: #2E4F97;}
.navbar-nav .nav-link:before{content: ""; background: #2E4F97; height: 2px; width: 0; position: absolute; left: 0; bottom: 0;}
.navbar-nav .nav-link:hover:before, .navbar-nav .nav-link:focus:before, .navbar-nav .nav-item .nav-link.active:before{width: 100%;}


.navbar-nav .dropdown-menu .dropdown-submenu .dropdown-menu {background: #ffffff; left: 100%; margin: 0; right: auto; top: 0; }
.navbar-nav .dropdown-submenu { display: block; position: relative; }

.header-2 #header-wrap .logo {display: none;}
.header-2 #header-wrap.fixed-header .logo {display: block;}
.header-2 #header-wrap .navbar-nav {margin-right: auto !important;}
.header-2 #header-wrap.fixed-header .navbar-nav {margin-right: inherit !important;}


.header-3 .top-bar{background: #ffffff; padding: 0;}
.header-3 .topbar-link li a{color: #23244a;}
.header-3 .search-box input[type="text"], .header-3 .search-box input[type="search"]{background: #121628}
.header-3 .topbar-link li a i {background: #2E4F97; color: #ffffff; height: 30px; line-height: 30px; margin-right: 5px; text-align: center; width: 30px;}

.header.header-4{position: relative;}
.header-4 .top-bar {background: #2E4F97;}
.header-4 #header-wrap {background: #121628;}
.header-4 .topbar-link li a:hover{color: #121628}

/* ------------------------
    Footer
------------------------*/
.footer{padding-top: 8rem; margin-top: 6rem;}
.primary-footer{padding: 50px 0;}
.secondary-footer{padding: 15px 0; background: #191919; color: #ffffff;}
.footer-logo img{height: 50px;}
.primary-footer, .primary-footer a{color: #ffffff;}
.primary-footer h5{color: #ffffff; margin-bottom: 20px; text-transform: uppercase;}

.footer-contact {position: absolute; top: 0; width: 100%;}
.footer-media{border-right: 1px dashed rgba(255,255,255,0.5); height: 100%;}
.footer-media i{background: #121628; color: #ffffff; display: inline-block; width: 70px; height: 70px; line-height: 70px; font-size: 34px; margin-bottom: 20px;}
.footer-media span{color: #ffffff; font-weight: 600; font-size: 16px; display: block; margin-bottom: 10px;}
.footer-media a, .footer-media p{color: #ffffff;}
.footer-media a:hover{color: #23244a;}

footer.dark-bg .social-icons li a{color: rgba(255,255,255,0.6);}
.footer-list li{margin-bottom: 15px; text-transform: capitalize;}
.footer-list li:last-child{margin-bottom: 0;}
.footer-list li a{position: relative; z-index: 99;}
.footer-list li i{margin-right: 5px; font-size: 12px; vertical-align: middle;}
.footer-list li a:before{content: ""; z-index: -1; background: #2E4F97; width: 0; height: 5px; position: absolute; right: 0; bottom: 0;}
.footer-list li a:hover{color: #ffffff;}
.footer-list li a:hover:before{width: 50px;}


.footer-2 .media-icon.style-2 li{border-right: none; padding: 30px 20px;}
.footer-2 .media-icon.style-2 li.white-bg span{color: #2E4F97}
.footer-2 .media-icon.style-2 li.white-bg a{color: #23244a;}


footer .recent-post li{border-bottom: 1px dashed #3c3c3c; padding-bottom: 15px;}
footer .recent-post li:last-child{border-bottom: none; padding-bottom: 0;}
footer .widget .recent-post-thumb{width: 50px; vertical-align: top;}
footer .widget .recent-post-desc a{color: #ffffff;}
footer .widget .recent-post-desc span{color: #2E4F97;}

.working-hours li{border-top: 1px dashed #3c3c3c; float: left; padding: 11px 0 12px; position: relative; text-align: right; width: 100%;}
.working-hours li:first-child, .footer-menu li:first-child {border-top: none;}
.working-hours li span {float: left; position: relative; text-align: left; text-transform: capitalize; width: auto;}

.copyright .footer-list li{border-right: 1px dashed rgba(255,255,255,0.1); display: inline-block; margin-bottom: 0; padding: 0 12px;}
.copyright .footer-list li:last-child{border-right: none; padding-right: 0;}
.copyright .footer-list li a{color: rgba(255,255,255,0.9);}
.copyright .footer-list li a:before{display: none;}
.copyright .footer-list li a:hover{padding: 0; color: #2E4F97}


/* ------------------------
    Page Title
------------------------*/
.page-title {display: block; padding-bottom: 130px; padding-top: 250px;}
.page-title h1{font-size: 40px; color: #ffffff; line-height: 40px; text-transform: uppercase; font-weight: 300;}
.page-title p{margin-bottom: 0; color: #ffffff}
.breadcrumb {padding: 15px; background: #ffffff; display: inline-block; border-radius: 0; margin: 0; text-transform: uppercase; color: #23244a; font-weight: 600;}
.breadcrumb-item{display: inline-block;}
.breadcrumb-item a{color: #23244a}
.breadcrumb-item.active, .breadcrumb-item a:hover{color: #2E4F97;}
.page-breadcrumb {position: absolute; bottom: 0; left: 50%;}


#newslatter .form-control{border:none; background: #ffffff;}
.form-btn{box-shadow: none; border: none; cursor: pointer; text-transform: uppercase; font-size: 16px; background: #2E4F97; color: #ffffff; height: 50px; line-height: 50px; padding: 0 15px;}



/* ------------------------
    Flat Icon
------------------------*/
@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon-.eot#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
font-style: normal;
}

.flaticon-24-hours:before { content: "\f100"; }
.flaticon-drawing:before { content: "\f101"; }
.flaticon-innovation-1:before { content: "\f102"; }
.flaticon-idea:before { content: "\f103"; }
.flaticon-alarm-clock:before { content: "\f104"; }
.flaticon-broken-link-1:before { content: "\f105"; }
.flaticon-broken-link:before { content: "\f106"; }
.flaticon-search-1:before { content: "\f107"; }
.flaticon-search:before { content: "\f108"; }
.flaticon-magnifier:before { content: "\f109"; }
.flaticon-cancel:before { content: "\f10a"; }
.flaticon-home:before { content: "\f10b"; }
.flaticon-map:before { content: "\f10c"; }
.flaticon-employee:before { content: "\f10d"; }
.flaticon-coffee-cup:before { content: "\f10e"; }
.flaticon-pencil:before { content: "\f10f"; }
.flaticon-project:before { content: "\f110"; }
.flaticon-engine:before { content: "\f111"; }
.flaticon-fuel-station:before { content: "\f112"; }
.flaticon-factory:before { content: "\f113"; }
.flaticon-maintenance:before { content: "\f114"; }
.flaticon-industrial-robot:before { content: "\f115"; }
.flaticon-gear:before { content: "\f116"; }
.flaticon-motor:before { content: "\f117"; }
.flaticon-worker:before { content: "\f118"; }
.flaticon-oil:before { content: "\f119"; }
.flaticon-angle-arrow-down:before { content: "\f11a"; }
.flaticon-paper-plane:before { content: "\f11b"; }
.flaticon-message:before { content: "\f11c"; }
.flaticon-opened-email-envelope:before { content: "\f11d"; }
.flaticon-phone-call:before { content: "\f11e"; }
.flaticon-online-support:before { content: "\f11f"; }
.flaticon-settings:before { content: "\f120"; }
.flaticon-networking:before { content: "\f121"; }
.flaticon-browser:before { content: "\f122"; }
.flaticon-time-management:before { content: "\f123"; }
.flaticon-chat-bubble:before { content: "\f124"; }
.flaticon-trophy:before { content: "\f125"; }
.flaticon-growth:before { content: "\f126"; }
.flaticon-innovation:before { content: "\f127"; }
