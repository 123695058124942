
#header-wrap:lang(ar){
direction: rtl;
}
.page-content:lang(ar),.post:lang(ar),.container:lang(ar){
  direction: rtl;
}
.dropdown-menu:lang(ar){
  text-align: right !important;
  direction: rtl;
}
.breadcrumb-item+.breadcrumb-item:lang(ar)::before{
  float: right !important;
}
.banner:lang(ar) h5{
  letter-spacing: 0px !important;
}

